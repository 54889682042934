<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="flex flex-col items-center justify-start flex-grow w-full text-yellow-50 p-3 pb-28"
      >
        <div
          class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
        >
          <div
            class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-gray-100"
          >
            <h2
              class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto"
            >
              <span class="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="247432cb-6e6c-4bec-9766-564ed7c230dc"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7"></circle>
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#247432cb-6e6c-4bec-9766-564ed7c230dc)"
                    width="52"
                    height="24"
                  ></rect>
                </svg>
              </span>
              CASINO
            </h2>
            <div>
              <h4
                class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400"
              >
                เดิมพันเลข Hash ของ Binance Smart Chain
              </h4>
            </div>
          </div>

          <div>
            <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              <router-link v-bind:to="'/one_hash'">
                <div
                  class="bg-gray-900 overflow-hidden shadow rounded-lg w-56 sm:w-60 relative"
                >
                  <img
                    :src="getCurrency['BNB'].logo"
                    alt="btc logo"
                    class="h-24 w-24 rounded-full absolute opacity-50 -top-6 -right-6 md:-right-4"
                  />
                  <div class="px-4 py-8 sm:p-9">
                    <dl>
                      <dd
                        class="mt-1 text-3xl sm:text-3xl leading-9 font-semibold text-white"
                      >
                        คู่ - คี่
                      </dd>
                    </dl>
                  </div>
                </div>
              </router-link>

              <router-link v-bind:to="'/two_hash'">
                <div
                  class="bg-gray-900 overflow-hidden shadow rounded-lg w-56 sm:w-60 relative"
                >
                  <img
                    :src="getCurrency['BNB'].logo"
                    alt="btc logo"
                    class="h-24 w-24 rounded-full absolute opacity-50 -top-6 -right-6 md:-right-4"
                  />
                  <div class="px-4 py-5 sm:p-9">
                    <dl>
                      <dd
                        class="mt-1 text-3xl sm:text-3xl leading-9 font-semibold text-white"
                      >
                        สูง - ต่ำ
                      </dd>
                    </dl>
                  </div>
                </div>
              </router-link>

              <router-link v-bind:to="'/last_hash'">
                <div
                  class="bg-gray-900 overflow-hidden shadow rounded-lg w-56 sm:w-60 relative"
                >
                  <img
                    :src="getCurrency['BNB'].logo"
                    alt="btc logo"
                    class="h-24 w-24 rounded-full absolute opacity-50 -top-6 -right-6 md:-right-4"
                  />
                  <div class="px-4 py-5 sm:p-9">
                    <dl>
                      <dd
                        class="mt-1 text-3xl sm:text-3xl leading-9 font-semibold text-white"
                      >
                        เลขท้าย
                      </dd>
                    </dl>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <footer>
          <StickFooter />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import { mapGetters, mapActions } from "vuex";
import { POOL_ADDRESS, VERSION } from "../../config";

import loader from "@/assets/svg/loader.vue";
import chameleon from "@/assets/svg/chameleon.vue";

import func from "@/widgets/func";
import label from "@/widgets/label";
import info from "@/api/info";

export default {
  data() {
    return { version: VERSION };
  },
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    ConnectWallet,
    StickFooter,
    FundSummary,
    loader,
    chameleon,
  },
  computed: mapGetters(["getCurrency"]),

  data() {
    return {
      funds: [],
      ready: false,
    };
  },
  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },

    getData() {
      info.fundsummary().then((res) => {
        res.data.forEach((e) => {
          var a = label.type(e._id.type);
          e.label = a.label;

          this.funds.push(e);
        });
        this.ready = true;
      });
    },
  },
  created() {
    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }

    this.getData();
  },
};
</script>
